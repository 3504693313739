import { Clients } from "data"
import React from "react"

export const Clientes = () => {
  const { color } = Clients()

  return (
    <section id="clientes" className="partial-home">
      <div className=" d-flex justify-content-center p-2">
        <div className="container">
          <h1 className="d-flex justify-content-center mb-5">
            Nossos clientes
          </h1>
          <div className="d-flex flex-row flex-wrap justify-content-center">
            {color.map(({ name, src }, ix) => {
              return (
                <img
                  src={src}
                  alt={name}
                  title={name}
                  className="m-3 img-fluid"
                  key={ix}
                />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
