import logo from "images/logo-info.png"
import React from "react"
import "./styles.css"

export const QuemSomos = () => {
  return (
    <section id="quem-somos" className="partial-home">
      <div className="d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-sm-4">
              <h1>Quem somos</h1>
              <p className="my-2">
                A Fábricainfo é referência no mercado brasileiro de digitalização, com mais de 900 milhões de documentos digitalizados e mais de 480 horas anuais economizadas por seus clientes na busca por informações.
              </p>
              <p className="my-2">
                Com mais de 20 anos desde a fundação, a empresa possui uma equipe de profissionais preparados e equipamentos modernos para oferecer sempre as melhores soluções para o seu negócio na hora de organizar e digitalizar seus arquivos.
              </p>
              <a href="http://www.fabricainfo.com/" target="_blank">
                Conheça a FabricaInfo
                <i className="fa fa-arrow-right ms-2" />
              </a>
            </div>
            <div className="col-sm-4 d-flex justify-content-center align-items-center">
              <img
                src={logo}
                className="img-fluid"
                alt="logo fabricainfo"
                title="logo fabricainfo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
