import { Seo } from "components"
import Layout from "layout"
import {
  Clientes,
  Consultor,
  Depoimentos,
  Funcoes,
  LGPD,
  Planos,
  Processos,
  QuemSomos,
  Sobre,
} from "partials"
import React from "react"

const Home = () => (
  <Layout>
    <Seo title="Home" />
    <Processos />
    <Sobre />
    <Funcoes />
    <Clientes />
    <LGPD />
    {/* <Depoimentos /> */}
    <Planos />
    <QuemSomos />
    <Consultor />
  </Layout>
)

export default Home
