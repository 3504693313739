import React from "react"
import "./styles.css"

export const Planos = () => {
  return (
    <section id="planos" className="partial-home">
      <div className=" d-flex justify-content-center align-items-center">
        <div className="container">
          <h1 className="d-flex justify-content-center my-2 mb-4">
            Veja nossos planos e preços
          </h1>
          <div className="row g-5">
            <div className="col-sm-4">
              <h5 className="text-center text-white">1 usuário - 50 MB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 1 usuário</p>
              <p><i className="fa fa-check-circle"/> 50 MB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">3 usuários - 15 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 3 usuários</p>
              <p><i className="fa fa-check-circle"/> 15 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">7 usuários - 35 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 7 usuários</p>
              <p><i className="fa fa-check-circle"/> 35 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">14 usuários - 70 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 14 usuários</p>
              <p><i className="fa fa-check-circle"/> 70 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">30 usuários - 150 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 30 usuários</p>
              <p><i className="fa fa-check-circle"/> 150 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">50 usuários - 250 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 50 usuários</p>
              <p><i className="fa fa-check-circle"/> 150 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">70 usuários - 350 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 70 usuários</p>
              <p><i className="fa fa-check-circle"/> 350 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">100 usuários - 500 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 100 usuários</p>
              <p><i className="fa fa-check-circle"/> 500 GB de armazenamento</p>
            </div>
            <div className="col-sm-4">
              <h5 className="text-center text-white">150 usuários - 500 GB</h5>
              <hr/>
              <p><i className="fa fa-check-circle"/> 150 usuários</p>
              <p><i className="fa fa-check-circle"/> 750 GB de armazenamento</p>
            </div>
            {/* <div className="d-flex justify-content-center my-2 mt-4">
              <a href="#" className="btn btn-outline-secondary">
                Fale com um consultor
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

