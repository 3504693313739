import React from "react"
import "./styles.css"

export const Consultor = () => {
  return (
    <section id="consultor" className="partial-home">
      <div className="d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row justify-content-sm-center">
            <div className="col-sm-6 text-center">
              <h1 className="my-3">
                Transforme digitalmente a sua empresa e torne a gestão de
                documentos mais ágil, simples e segura.
              </h1>
              {/* <a href="#" className="btn btn-outline-secondary my-3">
                Fale com um consultor
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
