import React from "react"
import lgpdImage from "../../images/Imagem_LGPD.png"
import "./styles.css"

export const LGPD = () => {
  return (
    <section
      id="lgpd"
      className="partial-home d-flex justify-content-center align-items-center"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-6 d-flex flex-column justify-content-center">
            <h1 className="mb-5">Já estamos adaptados à LGPD</h1>
            <p className="mb-5">
              Nosso sistema ajuda a sua empresa a se adequar à Lei Geral de Proteção de Dados (LGPD), pois as informações e dados pessoais de todos os usuários  estarão protegidos e assegurados de ameaças. Além disso, o Godocs é assegurado pelo decreto 10.278/2020 que regulamenta o descarte de documentos após seguirem os requisitos para a digitalização.
            </p>
            <a href="https://www.gov.br/cidadania/pt-br/acesso-a-informacao/lgpd" target="_blank">
              Saiba mais <i className="fa fa-arrow-right text-white" />
            </a>
          </div>
          <div className="col-sm-6 d-flex justify-content-center align-items-center">
            <div id="video-sample" className="rounded">
              <img width="100%" height="100%" src={lgpdImage}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
