import React from "react"
import video from "../../videos/GO DOCS 01.mp4"
import "./styles.css"

export const Sobre = () => {
  return (
    <section
      id="sobre"
      className="partial-home d-flex justify-content-center align-items-center"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-4 d-flex flex-column justify-content-center mx-auto">
            <h1 className="mb-3">O que é o GoDocs?</h1>
            <p>
              O sistema GoDocs tem como objetivo tornar possível o gerenciamento eletrônico de documentos de uma forma simples e rápida através de qualquer dispositivo eletrônico.
            </p>
            <p>
              Além de ajudar sua empresa a se adequar à Lei Geral de Proteção de Dados (LGPD), pois as informações e dados pessoais de todos os usuários estarão protegidos e assegurados de ameaças, nosso sistema possibilita a criação de trâmites personalizados para cada departamento.
            </p>
            <p>
              Tudo isso e muito mais em um só lugar.
            </p>
          </div>
          <div className="col-sm-4 mx-auto my-3">
            <div className="d-flex justify-content-center align-items-center">
              <div id="video-sample" className="img-thumbnail">
                <video width="100%" height="100%" controls>
                  <source src={video} type="video/mp4"/>
                  Seu navegador não possui suporte à tag video.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
