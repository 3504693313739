import { Clients } from "data"
import React from "react"
import "./styles.css"

export const Processos = () => {
  const { bw } = Clients()
  return (
    <section
      id="processos"
      className="partial-home d-flex justify-content-center align-items-center"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h1 className="mb-5">
              É hora de <span>transformar</span>
              <br /> seus processos
            </h1>
            <p className="mb-5">
              Faça negócios mais rápido, aumente a conformidade, com a melhor
              experiência do cliente e workflows personalizados.
            </p>
            {/* <a className="btn btn-outline-primary mb-5" href="">
              Solicite uma demonstração
            </a> */}
            <h2 className="mb-5">Alguns de nossos clientes</h2>
            <div className="d-flex justify-content-start flex-wrap">
              {bw.map(({ name, src }, ix) => {
                return (
                  <img
                    src={src}
                    alt={name}
                    title={name}
                    className="img-fluid m-2"
                    key={ix}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-sm-6"></div>
      </div>
    </section>
  )
}
