import React from "react"
import "./styles.css"

export const Funcoes = () => {
  return (
    <section id="funcoes" className="partial-home">
      <div className="d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-sm-6 col-xs-8 my-3">
              <h1 className="mb-5">Funções exclusivas de um GED</h1>
              <div>
                <h2>
                  <span className="btn btn-secondary me-2">
                    <i className="fa fa-search" />
                  </span>
                  Pesquisa avançada
                </h2>

                <p className="mb-3">
                  Acesso a um sistema de busca rápida que vai permitir você encontrar seus documentos em poucos minutos.
                </p>
              </div>
              <div>
                <h2>
                  <span className="btn btn-secondary me-2">
                    <i className="fa fa-sitemap" />
                  </span>
                  Workflow
                </h2>
                <p className="mb-3">
                  Gerencia o trâmite de documentos e processos entre departamentos para você saber onde, e em qual etapa, cada documento se encontra.
                </p>
              </div>
              <div>
                <h2>
                  <span className="btn btn-secondary me-2">
                    <i className="fa fa-folder-open" />
                  </span>
                  Gestão de documentos físicos
                </h2>
                <p className="mb-3">
                  Controle completo e gestão da guarda de documentos físicos dentro de um único software.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
